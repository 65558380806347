<template>
  <div class="login"  id="constellation-net">
    <div class="form">
        <div class="title text-center">
          <h1>Doble autenticación</h1>
        </div><div class="row form-group">
        <h2 class="col-sm-8">Doble autenticación</h2>
        <div class="col-sm-4">
          <div class="row">
            <div class="col-sm-2">Estado: </div>
            <div class="col-sm-2"><BSwitch :value="tmp_two_authentication != 'na'" id="2fa_auth" name="2fa_auth" textoff="Deshabilitado" texton="Habilitado" type="success" @checked="verificar_estado_auth"/></div>
          </div>
        </div>
      </div>
      <div class="row form-group">
        <label for="" class="col-form-label col-sm-4 col-md-3 offset-md-1">Tipo de autenticación</label>
        <div class="col-sm-8 col-md-6">
          <select v-model="tmp_two_authentication" name="twoFaType" id="twoFaType" class="form-control" @change="tipo_auth">
            <option value="na">Selecciona una opción</option>
            <template v-for="cat_option in catalogo_auth.opciones">
            <option v-if="cat_option.activo == 1" :key="cat_option.id" :value="cat_option.valor">{{ cat_option.nombre }}</option>
            </template>
          </select>
        </div>
      </div>
      <template v-if="image_qr">
      <div class="row form-group">
        <div class="col-sm-12">
          Al seleccionar aplicación es necesario que instales en tu teléfono la aplicación de <a :href="url_google" target="__blank">Google Authenticator</a> o <a :href="url_microsoft" target="__blank">Microsoft Authenticator</a>, ambas aplicaciones son compatibles, puedes usar la que mas te guste.
        </div>
      </div>
      <div class="row form-group">
        <label for="" class="col-form-label col-sm-4 col-md-3 offset-md-1">Código QR</label>
        <div class="col-sm-8 col-md-6"><img :src="image_qr" alt="Código QR"></div>
      </div>
      <div class="row form-group">
        <label for="" class="col-form-label col-sm-4 col-md-3 offset-md-1">Código 1</label>
        <div class="col-sm-4">
          <input v-model="qr_code_validate_one" type="text" id="qr_code_one" name="qr_code_one" class="form-control" :disabled="qr_code_validate_one_validate" autocomplete="off">
        </div>
        <div class="col-sm-4"><button class="btn secondary-btn" @click="validar_codigo" :disabled="qr_code_validate_one_validate">Validar código</button></div>
      </div>
      <div v-if="qr_code_validate_one_validate" class="row form-group">
        <label for="" class="col-form-label col-sm-4 col-md-3 offset-md-1">Código 2</label>
        <div class="col-sm-4">
          <input v-model="qr_code_validate_two" type="text" id="qr_code_one" name="qr_code_one" class="form-control" :disabled="qr_code_validate_two_validate" autocomplete="off">
        </div>
        <div class="col-sm-4"><button class="btn secondary-btn" @click="validar_codigo" :disabled="qr_code_validate_two_validate">Validar código</button></div>
      </div>
      <div class="row form-group">
        <div class="col-sm-6">
          Manual para agregar a BienParaBien en Google Authenticator

          <ol>
            <li>Abre la app</li>
            <li>Toca icono de + que se encuentra abajo a la derecha</li>
            <li>Toca la opción que dice "Escanear un código QR"</li>
            <li>Te pedirá permisos para acceder a la cámara, concede los permisos y escanea el código QR que aquí te mostramos</li>
            <li>Una ves agregado el código en la app tendrás que validar 2 códigos diferentes</li>
            <li>Si los 2 códigos son válidos se habrá activado la doble autenticación</li>
          </ol>
        </div>
        <div class="col-sm-6">
          Manual para agregar a BienParaBien Microsoft Authenticator

          <ol>
            <li>Abre la app</li>
            <li>Toca los 3 puntos arriba a la derecha</li>
            <li>Toca la opción que dice "Agregar cuenta"</li>
            <li>Toca la opción que dice "Otra cuenta (Google, Facebook, etc)</li>
            <li>Te pedirá permisos para acceder a la cámara, concede los permisos y escanea el código QR que aquí te mostramos</li>
            <li>Una ves agregado el código en la app tendrás que validar 2 códigos diferentes</li>
            <li>Si los 2 códigos son válidos se habrá activado la doble autenticación</li>
          </ol>
        </div>
      </div>
      </template>
    </div>
    <vue-particles color="#3DADF2"
      :particleOpacity="0.7"
      :particlesNumber="76"
      shapeType="circle"
      :particleSize="5"
      linesColor="#3DADF2"
      :linesWidth="2"
      :lineLinked="true"
      :lineOpacity="1"
      :linesDistance="150"
      :moveSpeed="4"
      :hoverEffect="true"
      hoverMode="grab"
      :clickEffect="true"
      clickMode="push">
    </vue-particles>
  </div>
</template>

<script type="text/javascript">
  import account from '@/api/account';
  import BSwitch from '@/components/Form/Switch'

  export default {
    data: function() {
      return {
        verify: {
          auth: 1
          ,contact: null
        }
        ,codigo: null
        ,twoFaAuth: false
        ,tmp_two_authentication: 'na'
        ,catalogo_auth: []
        ,image_qr: null
        ,qr_code_validate_one: null
        ,qr_code_validate_one_validate: false
        ,qr_code_validate_two: null
        ,qr_code_validate_two_validate: false
        ,twoFaAuthFinish: false
        ,disable_two_auth: false
      }
    }
    ,mounted: function() {
      this.verify = this.$store.state.verify;

      this.$refs.codigo_otp.focus();
    }
    ,methods: {
      valida_codigo: async function() {
        try {
          let res;
          if (this.verify.auth != 4)
            res = (await account.validar_doble_auth({codigo: this.codigo, verificationCode: this.verify.verificationCode})).data;
          else
            res = (await account.validar_codigo_tfa_auth({codigo: this.codigo, verificationCode: this.verify.verificationCode})).data;
          
          this.$log.info('res', res);

          this.$store.commit('setCredentials', res);

          if (!res.user.password_reset)
            this.$router.push({name: 'dashboard'});
          else
            this.$router.push({name: 'constelacion_password_reset'});
        }catch(e) {
          this.$log.info('error',e.response.status);

          if (e.response && e.response.status && e.response.status == 403)
            return this.$helper.showMessage('Error','Acceso denegado','error','alert');

          this.$helper.showAxiosError(e, 'Error');
        }
      }
      ,reenviar_doble_auth: async function() {
        try {
          let res = (await account.reenviar_doble_auth({verificationCode: this.verify.verificationCode})).data;
          this.$log.info('res', res);

          this.$store.commit('set',{verify: res});

          this.$helper.showMessage('Código enviado','Se reenvio el código de validación a '+res.contact,'info','alert');
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e, 'Error');
        }
      }
      ,tipo_auth: async function() {
        this.$log.info('tipo_auth: '+this.tmp_two_authentication);

        try {
          switch(this.tmp_two_authentication) {
            case '1':
            case '2':
              let res = (await api.activar_doble_auth_otp({tipo: this.tmp_two_authentication})).data;
              this.$log.info('res',res);
              if (res.success)
                this.twoFaAuthFinish = true;
              break;
            case '4':
              this.image_qr = (await api.obtener_qr_tfa()).data;
              break;
          }
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
@import '@/general-styles/components/forms.scss';
@import '@/general-styles/components/particles.scss';

  .login {
    position: relative;
    z-index: 1;
    .logo {
      z-index: 1;
      img {
        height: 340px;
        width: 300px;
        margin: 1rem 0;
      }
    }
    .form {
      background: rgba(255,255,255,.69);
      padding: 2rem;
      width: 400px;
      .title {
        h1 {
          color: #fff !important;
        }
      }
      .principal-btn {
        margin: 1.5rem 0;
      }
      .showHidePassword {
        width: 30px;
        position: absolute;
        top: 33px;
        right: 21px;
        img {
          width: 100% !important;
        }
      }
      .form-body{
        padding: 0;
        margin: 0;
        background: transparent;
      }
    }
  }
  @media only screen and (max-width: 1023px){
    .login{
      .form{
        background: transparent;
      }
      .form-body{
        background: rgba(255,255,255,.69) !important;
        padding: 0 1rem !important;
        label{
          color: #4e4e4e !important;
        }
      }
    }
  }
</style>